import MessageE from './index.vue'
import { createVNode, render } from 'vue'
/**
 * @param {string} type 提示类型
 * @param {string} text 提示文字
 * @param {number} duration 提示持续时间
 * @param {string} position 提示位置
 * eg: Message({type:'error',text:'提示文字',duration:2000,position:'top'})
 *
 */

type Options = {
  type?: string
  text: string
  duration?: number
  position?: string
  direction?: string
}
type Timer = ReturnType<typeof setTimeout>

// 定时器标识
let timer: Timer

const div = document.createElement('div')
div.setAttribute('class', 'message-container')
document.body.appendChild(div)

export const Message = (options: Options) => {
  const { type, text, duration = 3000, direction } = options
  // 渲染组件
  // 1. 导入消息提示组件
  // 2. 将消息提示组件编译为虚拟节点(dom节点)
  const vNode = createVNode(MessageE, { type, text, direction })
  // 将实例挂载到DOM容器 render(虚拟节点,DOM容器)
  render(vNode, div)
  // 定时器
  clearTimeout(timer)
  timer = setTimeout(() => {
    render(null, div)
  }, duration)
}
